<template>
  <div class="overflow-y-scroll scrollbar-hide" ref="page">
    <div class="relative z-10 text-center flex flex-col -mb-8 mx-6 h-full">
      <h5 class="text-pink-cs text-base mt-20">اطلاعات کلی</h5>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)" novalidate>
          <validation-provider name="عنوان اگهی" rules="required" v-slot="{ errors }">
            <div class="mt-7 text-right">
              <label class="text-white text-xs pr-3">عنوان اگهی</label>
              <input
                  type="text"
                  class="input-border mt-2"
                  placeholder="عنوان"
                  v-model="title"

              >
            </div>
            <span class="text-sm text-red-500 text-right ">{{ errors[0] }}</span>
          </validation-provider>
          <validation-provider name="متراژ" rules="required" v-slot="{ errors }">
            <div class="mt-7 text-right">
              <label class="text-white text-xs pr-3">متراژ(متر مربع)</label>
              <input

                  type="number"
                  class="input-border mt-2"
                  placeholder="متراژ"
                  v-model="options.area"
              >
            </div>
            <span class="text-sm text-red-500 text-right ">{{ errors[0] }}</span>
          </validation-provider>
          <div v-if="addType === 'mortgageRent' || addType === 'rent' ">
            <validation-provider name="ودیعه" rules="required" v-slot="{ errors }">
              <div class="mt-7 text-right">
                <label class="text-white text-xs pr-3">ودیعه</label>
                <input
                    v-currency="{ distractionFree: false }"
                    ref="downPayment"
                    class="input-border mt-2"
                    placeholder="ودیعه ملک"
                    v-model="downPayment"
                />
              </div>
              <span class="text-sm text-red-500 text-right ">{{ errors[0] }}</span>
            </validation-provider>
            <validation-provider name="اجاره" rules="required" v-slot="{ errors }">

              <div class="mt-7 text-right">
                <label class="text-white text-xs pr-3">اجاره</label>
                <input
                    v-currency="{ distractionFree: false }"
                    ref="rent_price"
                    class="input-border mt-2"
                    placeholder="اجاره ملک"
                    v-model="rent_price"
                />
              </div>
              <span class="text-sm text-red-500 text-right ">{{ errors[0] }}</span>

            </validation-provider>
          </div>
          <div v-else>
            <validation-provider name="قیمت فروش کل" rules="required" v-slot="{ errors }">
              <div class="mt-7 text-right">
                <label class="text-white text-xs pr-3">قیمت فروش کل</label>
                <input
                    v-currency="{ distractionFree: false }"
                    ref="price"
                    v-model="price"
                    placeholder="قیمت فروش کل"
                    class="input-border mt-2"
                />
              </div>
              <span class="text-sm text-red-500 text-right ">{{ errors[0] }}</span>

            </validation-provider>

          </div>
          <SelectInput
              name="number_of_bathrooms"
              :count="30"
              title="تعداد حمام"
              :checked="options.number_of_bathrooms"
              v-model="options.number_of_bathrooms"
          />
          <SelectInput
              name="number_of_parking"
              :count="40"
              title="تعداد پارکینگ"
              :checked="options.number_of_parking"
              v-model="options.number_of_parking"
          />
          <SelectInput
              name="number_of_washroom"
              :count="30"
              title="تعداد سرویس بهداشتی"
              :checked="options.number_of_washroom"
              v-model="options.number_of_washroom"
          />
          <SelectInput
              name="number_of_bedrooms"
              :count="30"
              title="تعداد اتاق خواب"
              :checked="options.number_of_bedrooms"
              v-model="options.number_of_bedrooms"
          />
          <validation-provider name="زیر بنا متر مربع"  v-slot="{ errors }">
            <div class="mt-7 text-right">
              <label class="text-white text-xs pr-3">زیر بنا</label>
              <input
                  type="number"
                  class="input-border mt-2"
                  placeholder="زیر بنا"
                  v-model="options.basement"
              >
            </div>
            <span class="text-sm text-red-500 text-right ">{{ errors[0] }}</span>
          </validation-provider>

          <validation-provider name="سن بنا"  v-slot="{ errors }">
            <div class="mt-7 text-right">
              <label class="text-white text-xs pr-3">سن بنا</label>
              <input
                  type="number"
                  class="input-border mt-2"
                  placeholder="سن بنا"
                  v-model="options.age"
              >
            </div>
            <span class="text-sm text-red-500 text-right ">{{ errors[0] }}</span>
          </validation-provider>
          <SelectInput
              name="numbers_of_floors"
              :count="30"
              title="تعداد طبقات"
              :checked="options.numbers_of_floors"
              v-model="options.numbers_of_floors"
          />
          <SelectInput
              name="number_of_units_per_floor"
              :count="30"
              title="تعداد واحد در هر طبقه"
              :checked="options.number_of_units_per_floor"
              v-model="options.number_of_units_per_floor"
          />
          <p class="text-sm text-white mb-2 mt-7">امکانات ویژه</p>
          <CheckboxInput
              :items="sepcialFeatures"
              v-model="selecetedSepcialFeatures"
          />
          <div class="extra-input" v-for="item in allFeatures" @click="openFeature(item.id,item.name)">
            <span>{{ item.name }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <path id="ic_add_circle_24px"
                    d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm5,11H13v4H11V13H7V11h4V7h2v4h4Z"
                    transform="translate(-2 -2)" fill="#644b92"/>
            </svg>
          </div>
          <SelectInput
              name="number_of_kitchens"
              :count="30"
              title="تعداد آشپزخانه"
              :checked="options.number_of_kitchens"
              v-model="options.number_of_kitchens"
          />
          <SelectInput
              name="number_of_sit_down_service"
              :count="30"
              title="تعداد مستر روم"
              :checked="options.number_of_master_rooms"
              v-model="options.number_of_master_rooms"
          />
          <SelectInput
              name="number_of_iranian_services"
              :count="30"
              title="تعداد سرویس ایرانی"
              :checked="options.number_of_iranian_services"
              v-model="options.number_of_iranian_services"
          />
          <SelectInput
              name="test55"
              :count="30"
              title="تعداد سرویس فرنگی"
              :checked="options.number_of_sit_down_service"
              v-model="options.number_of_sit_down_service"
          />
          <div class="text-right">
            <p class="text-sm text-white mb-2 mt-7">نما</p>
            <toggle-switch
                class="toggle-button"
                :options="myOptions"
                v-model="options.facade"
            />
          </div>
          <div class="flex justify-between mt-5">
            <div>
              <select class="select-cs" @change="handleDischarge">
                <option value="dischargeTrue">تخلیه شده</option>
                <option value="dischargeFalse" selected>تخلیه نشده</option>
              </select>
            </div>
            <div v-if="isDiscarge">
              <div class="check-estate__form-group text-right mr-4">
                <label>تاریخ تخلیه</label>
                <DatePicker
                    v-model="options.discharge_date"
                    type="date"
                    color="#361c3b"
                    custom-input="#dateTime2"
                    format="X"
                    display-format="jYYYY/jMM/jDD"
                />
                <input
                    id="dateTime2"
                    type="text"
                    class="input-border mt-3"
                    placeholder="تاریخ را وارد کنید"
                >
              </div>
              <div class="check-estate__form-group text-right mr-4">
                <label>تاریخ تحویل</label>
                <DatePicker
                    v-model="options.delivery_date"
                    type="date"
                    color="#361c3b"
                    custom-input="#dateTime3"
                    format="X"
                    display-format="jYYYY/jMM/jDD"
                />
                <input
                    id="dateTime3"
                    type="text"
                    class="input-border mt-3"
                    placeholder="تاریخ را وارد کنید"
                >
              </div>
            </div>
          </div>
          <div class="flex justify-between mt-2">
            <div>
              <select class="select-cs" @change="handleInstalment">
                <option value="instalmentTrue" >وام دارد</option>
                <option value="instalmentFalse" selected>وام ندارد</option>
              </select>
            </div>
            <div v-if="isInstalment">
              <div class="mt-7 text-right mr-4">
                <label class="text-white text-xs pr-3">مبلغ اقساط</label>
                <input
                    v-currency="{ distractionFree: false }"
                    ref="instalment"
                    class="input-border mt-2"
                    placeholder="مبلغ اقساط را وارد کنید"
                    v-model="instalment_amount"
                />
              </div>
              <div class="mt-7 text-right mr-4">
                <label class="text-white text-xs pr-3">تعداد اقساط</label>
                <input
                    class="input-border mt-2"
                    placeholder="تعداد اقساط را وارد کنید"
                    v-model="options.instalment_count"
                />
              </div>
            </div>
          </div>
          <validation-provider name="عرض گذر" v-slot="{ errors }">
            <div class="mt-7 text-right">
              <label class="text-white text-xs pr-3">عرض گذر(متر)</label>
              <input
                  type="number"
                  class="input-border mt-2 text-center"
                  placeholder="متراژ را وارد کنید"
                  v-model="options.width_country"
              >
            </div>
            <span class="text-sm text-red-500 text-right ">{{ errors[0] }}</span>
          </validation-provider>
          <div class="text-right">
            <p class="text-sm text-white mb-2 mt-7">موقعیت ملک</p>
            <toggle-switch
                class="toggle-button"
                :options="property_position"
                v-model="options.property_position"
            />
          </div>
          <div class="flex justify-between gap-5 mt-2">
            <div>
              <validation-provider name="طول ملک" v-slot="{ errors }">
                <div class="mt-7 text-right">
                  <label class="text-white text-xs pr-3">طول ملک (متر)</label>
                  <input
                      type="number"
                      class="input-border mt-2 text-center"
                      v-model="options.width_of_passage"
                  >
                </div>
                <span class="text-sm text-red-500 text-right ">{{ errors[0] }}</span>
              </validation-provider>
            </div>
            <div>
              <validation-provider name="عرض ملک" v-slot="{ errors }">
                <div class="mt-7 text-right">
                  <label class="text-white text-xs pr-3">عرض ملک(متر)</label>
                  <input
                      type="number"
                      class="input-border mt-2 text-center"
                      v-model="options.property_length"
                  >
                </div>
                <span class="text-sm text-red-500 text-right ">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </div>
          <validation-provider name="نوع نما"  v-slot="{ errors }">
            <div class="mt-7 text-right">
              <label class="text-white text-xs pr-3">نوع نما</label>
              <input
                  type="text"
                  placeholder="مثال : آجر"
                  class="input-border mt-2"
                  v-model="options.view_type"
              >
            </div>
            <span class="text-sm text-red-500 text-right ">{{ errors[0] }}</span>

          </validation-provider>
          <div class="text-right">
            <p class="text-sm text-white mb-2 mt-7">نوع واحد</p>
            <toggle-switch
                class="toggle-button"
                :options="apartment_unit_type"
                v-model="options.apartment_unit_type"
            />
          </div>
          <div class="mt-7 text-right">
            <label class="text-white text-xs pr-3">نوع ساختمان</label>
            <input
                type="text"
                class="input-border mt-2"
                v-model="options.building_type"
            >
          </div>
          <div class="mt-7 text-right">
            <label class="text-white text-xs pr-3">نوع پروانه</label>
            <input
                type="text"
                class="input-border mt-2"
                v-model="options.license_type"
            >
          </div>
          <validation-provider name="شماره پلاک" v-slot="{ errors }">
            <div class="mt-7 text-right">
              <label class="text-white text-xs pr-3">شماره پلاک</label>
              <input
                  type="number"
                  class="input-border mt-2"
                  v-model="options.number_plates"
              >
            </div>
            <span class="text-sm text-red-500 text-right ">{{ errors[0] }}</span>
          </validation-provider>
          <validation-provider name="نوع سازه" v-slot="{ errors }">
            <div class="mt-7 text-right">
              <label class="text-white text-xs pr-3">نوع سازه</label>
              <input
                  type="text"
                  class="input-border mt-2"
                  v-model="options.structure_type"
              >
            </div>
            <span class="text-sm text-red-500 text-right ">{{ errors[0] }}</span>
          </validation-provider>
          <validation-provider name="نوع سند" v-slot="{ errors }">
            <div class="mt-7 text-right">
              <label class="text-white text-xs pr-3">نوع سند</label>
              <input
                  type="text"
                  class="input-border mt-2"
                  v-model="options.document_type"
              >
            </div>
            <span class="text-sm text-red-500 text-right ">{{ errors[0] }}</span>

          </validation-provider>
          <validation-provider name="توضیحات آگهی"v-slot="{ errors }">
            <div class="mt-7 text-right">
              <label class="text-white text-xs pr-3">توضیحات آگهی</label>
              <textarea
                  rows="5"
                  class="input-border  bg-transparent mt-2"
                  v-model="description"
              ></textarea>
            </div>
            <span class="text-sm text-red-500 text-right ">{{ errors[0] }}</span>
          </validation-provider>
          <div class="toTop" @click="toTop">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7.41" viewBox="0 0 12 7.41">
            <path id="ic_chevron_right_24px" d="M1.41,0,0,1.41,4.58,6,0,10.59,1.41,12l6-6Z" transform="translate(0 7.41) rotate(-90)" fill="#fff"/></svg>
          </span>
            بالای صفحه
          </div>
          <div class="mt-4 pb-4 mb-12">
            <button class="btn-custom w-full">
              ثبت و ادامه
              <span class="svg-btn-icon2 bg-orange transform rotate-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>

        </span>
            </button>
          </div>

        </form>
      </ValidationObserver>


      <div class="extra-input__popup" v-if="openPopUp">
        <p class="text-pink-cs text-right text-sm mb-1 mr-2">{{popUpTitle}}</p>
        <checkboxInput
            :items="selectFeatures.value"
            v-model="FeatureArray"
        />

        <div class="next-btn">
          <a class="btn-custom" @click.prevent="handleClosePopUp(selectFeatures.id)">
            ثبت
            <span class="svg-btn-icon2 bg-orange transform rotate-0">
        <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>
        </span>
          </a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Vue from 'vue'
import SelectInput from "../inputs/SelectInput";
import CheckboxInput from "../inputs/CheckboxInput";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import ToggleSwitch from 'vuejs-toggle-switch'
import axios from "../../plugins/axios";
import { getValue} from 'vue-currency-input'
import Multiselect from 'vue-multiselect'
import DatePicker from 'vue-persian-datetime-picker'


Vue.use(ToggleSwitch)
export default {
  name: "Step5",
  components: {
    SelectInput,
    VueSlider,
    CheckboxInput,
    Multiselect,
    DatePicker
  },
  data() {
    return {
      handleScroll: {
        scrollDown: false,
        scrollUp: false,
        offsetY: 300
      },
      instalment_amount : this.$store.state.editEstate.options.instalment_amount,
      sepcialFeatures: [],
      isDiscarge : true,
      isInstalment : false,
      selecetedSepcialFeatures :[],
      openPopUp: false,
      myOptions: {
        items: {
          preSelected: 'شمالی',
          labels: [
            {name: 'شمالی'},
            {name: 'جنوبی'},
            {name: 'شرقی'},
            {name: 'غربی'},
          ]
        }

      },
      allFeatures: [],
      selectFeatures: {
        id : '',
        value : []
      },
      addType: this.$store.state.editEstate.advertisement_type,
      title : '',
      description :'',
      property_position : {
        items: {
          preSelected: 'درب ساختمان',
          labels: [
            {name: 'درب ساختمان'},
            {name: 'دوبرکوچه'},
            {name: 'درب حیاط'},
          ]
        }
      },
      apartment_unit_type : {
        items: {
          preSelected: 'فلت',
          labels: [
            {name: 'فلت'},
            {name: 'دوبلکس'},
            {name: 'تریپلکس'},
          ]
        }
      },
      options : {
        area : null,
        facade : null,
        width_country : null,
        property_position : null,
        width_of_passage : null,
        property_length : null,
        view_type : null,
        apartment_unit_type : null,
        building_type : '',
        license_type : '',
        number_plates : null,
        structure_type : null,
        price : 0,
        downPayment : 0,
        rent_price : 0,
        discharge_date : null,
        delivery_date : null,
        instalment_amount : null,
        instalment_count : null,
        number_of_bathrooms : 0,
        number_of_parking : 0,
        number_of_iranian_services : 0,
        number_of_bedrooms : 0,
        numbers_of_floors : 0,
        number_of_master_rooms : 0,
        number_of_units_per_floor : 0,
        number_of_kitchens : 0,
        number_of_sit_down_service : 0,
        number_of_washroom : 0,
        basement : null,
        document_type : '',
        otherFeatures : {},
      },
      FeatureArray : [],
      popUpTitle : '',
      price : this.$store.state.editEstate.options.price,
      downPayment : this.$store.state.editEstate.options.downPayment,
      rent_price : this.$store.state.editEstate.options.rent_price
    }
  },
  methods: {


    openFeature(id , name) {
      this.openPopUp = true;
      const selectFeature = this.allFeatures.find(item => item.id === id);
      this.selectFeatures.value = selectFeature.value;
      this.selectFeatures.id = selectFeature.id
      const idOtherFeatures = this.options.otherFeatures[this.selectFeatures.id]
      if(idOtherFeatures && idOtherFeatures !== []){
        this.FeatureArray = idOtherFeatures
      }
      this.$store.commit('general/CHANGE_IS_SHOW_BACK', false)

      this.popUpTitle = name
    },
    toTop(){
      this.$refs.page.scrollTo({top: 0, behavior: 'smooth'})
    },
    handleInstalment(e){
      if (e.target.value === 'instalmentFalse'){
        this.isInstalment = false;
      }else{
        this.isInstalment = true
      }
    },
    handleDischarge(e){
      if (e.target.value === 'dischargeFalse'){
        this.isDiscarge = true;
      }else{
        this.isDiscarge = false
      }
    },
    onSubmit(){

      const data = {
        title : this.title,
        description : this.description,
        options :{
          age : this.options.age,
          area : this.options.area,
          price : this.options.price,
          facade : this.options.facade,
          downPayment : this.options.downPayment,
          rent_price : this.options.rent_price,
          discharge_date : this.options.discharge_date,
          delivery_date : this.options.delivery_date,
          instalment_amount : this.options.instalment_amount,
          instalment_count : this.options.instalment_count,
          number_of_washroom : this.options.number_of_washroom,
          number_of_bathrooms : this.options.number_of_bathrooms,
          number_of_parking : this.options.number_of_parking,
          number_of_master_rooms : this.options.number_of_master_rooms,
          number_of_iranian_services : this.options.number_of_iranian_services,
          number_of_bedrooms : this.options.number_of_bathrooms,
          width_country : this.options.width_country,
          property_position : this.options.property_position,
          width_of_passage : this.options.width_of_passage,
          property_length : this.options.property_length,
          view_type : this.options.view_type,
          apartment_unit_type : this.options.apartment_unit_type,
          building_type : this.options.building_type,
          license_type : this.options.license_type,
          number_plates : this.options.number_plates,
          structure_type : this.options.structure_type,
          numbers_of_floors : this.options.numbers_of_floors,
          number_of_units_per_floor : this.options.number_of_units_per_floor,
          number_of_kitchens : this.options.number_of_kitchens,
          number_of_sit_down_service : this.options.number_of_sit_down_service,
          basement : this.options.basement,
          document_type : this.options.document_type,
          otherFeatures : this.options.otherFeatures,
          sepcialFeatures : this.selecetedSepcialFeatures
        },
      }

      this.$store.commit('editEstate/SET_STEP5' , data)
        this.$router.push({name : 'EditEstateStep6'})

    },
    handleClosePopUp(id){
      this.options.otherFeatures[id] = this.FeatureArray;
      this.FeatureArray = [];
      this.$store.commit('general/CHANGE_IS_SHOW_BACK', true)
      this.openPopUp = false

    },

  },
  mounted() {
    axios.get('options/house').then(({data}) => {
      data.data.find((item) => {
        if (item.id === "sepcialFeatures") {
          this.sepcialFeatures = item.value
        }
      });
      data.data.forEach((item) => {
        if (item.id !== "sepcialFeatures") {
          if(item.value.length > 0){
            this.allFeatures.push(item)
          }
        }
      })
    })
    this.title = this.$store.state.editEstate.title
    this.description = this.$store.state.editEstate.description
    this.options.age = this.$store.state.editEstate.options.age
    this.options.area = this.$store.state.editEstate.options.area
    this.options.price = this.$store.state.editEstate.options.price
    this.options.facade = this.$store.state.editEstate.options.facade
    this.options.downPayment = this.$store.state.editEstate.options.downPayment
    this.options.rent_price = this.$store.state.editEstate.options.rent_price
    this.options.number_of_washroom = this.$store.state.editEstate.options.number_of_washroom
    this.options.number_of_bathrooms = this.$store.state.editEstate.options.number_of_bathrooms
    this.options.number_of_parking = this.$store.state.editEstate.options.number_of_parking
    this.options.number_of_iranian_services = this.$store.state.editEstate.options.number_of_iranian_services
    this.options.number_of_bathrooms = this.$store.state.editEstate.options.number_of_bathrooms
    this.options.discharge_date = this.$store.state.editEstate.options.discharge_date
    this.options.delivery_date = this.$store.state.editEstate.options.delivery_date
    this.options.instalment_amount = this.$store.state.editEstate.options.instalment_amount
    this.options.instalment_count = this.$store.state.editEstate.options.instalment_count
    this.options.width_country = this.$store.state.editEstate.options.width_country;
    this.options.property_position = this.$store.state.editEstate.options.property_position;
    this.options.width_of_passage = this.$store.state.editEstate.options.width_of_passage;
    this.options.property_length = this.$store.state.editEstate.options.property_length;
    this.options.view_type = this.$store.state.editEstate.options.view_type;
    this.options.apartment_unit_type = this.$store.state.editEstate.options.apartment_unit_type;
    this.options.building_type = this.$store.state.editEstate.options.building_type;
    this.options.license_type = this.$store.state.editEstate.options.license_type;
    this.options.number_plates = this.$store.state.editEstate.options.number_plates;
    this.options.structure_type = this.$store.state.editEstate.options.structure_type;
    this.options.numbers_of_floors = this.$store.state.editEstate.options.numbers_of_floors
    this.options.number_of_units_per_floor = this.$store.state.editEstate.options.number_of_units_per_floor
    this.options.number_of_kitchens = this.$store.state.editEstate.options.number_of_kitchens
    this.options.number_of_sit_down_service = this.$store.state.editEstate.options.number_of_sit_down_service
    this.options.basement = this.$store.state.editEstate.options.basement
    this.options.document_type = this.$store.state.editEstate.options.document_type
    this.options.otherFeatures = this.$store.state.editEstate.options.otherFeatures
    this.selecetedSepcialFeatures = this.$store.state.editEstate.options.otherFeatures.sepcialFeatures
  },
  watch: {
    price() {
      this.options.price = getValue(this.$refs.price);
    },
    rent_price() {
      this.options.rent_price = getValue(this.$refs.rent_price);
    },
    downPayment() {
      this.options.downPayment = getValue(this.$refs.downPayment);
    },
    instalment_amount(){
      this.options.instalment_amount = getValue(this.$refs.instalment);
    }
  }
}
</script>

<style>
.vue-slider-dot-tooltip-inner-bottom::after {
  content: unset;
}

.vue-slider-dot-tooltip-inner {
  background: transparent;
  padding-top: 0;
}

.vue-slider-process {
  background-color: #FF7400;
  box-shadow: 0 0 7px 0px #ff7400;
}

.toggle-switch {
  direction: ltr;
  border: 1px solid #FF7400 !important;
  border-radius: 1rem;
  height: 2.9rem !important;
}

.toggle-switch li label {
  background: transparent !important;
  border: none !important;
  transition: revert !important;
}

.toggle-switch li label.active {
  color: white !important;
  font-size: 14px !important;
  font-family: 'Anjoman' !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.7rem;
}

.toggle-switch li label.selected {
  background: #644B92 !important;
  border-radius: 1rem !important;
  font-weight: normal !important;
  margin: 0.1rem 0;
  height: 2.7rem !important;
  top: -1px !important;
}
.select--activity.w-half{
  width: 100% !important;
}

.toggle-switch {
  width: 100% !important;
  direction: ltr;
  border: 1px solid #FF7400 !important;
  border-radius: 1rem;
  height: 2.9rem !important;
}
</style>